/* --------------------------------------------------------------------------------------
   xwToggleSwitch.directive.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

/*eslint semi: ["error", "never"]*/
 export class xwToggleSwitch {
  /*@ngInject*/
  constructor () {
    this.restrict = 'A'
    this.require = '?ngModel'
    this.scope = {
      onSwitch : '&'
    }
  }
  link(scope, elem, attrs, ngModel) {
    var options = {}
    options.theme = attrs.theme
    options.widgetSize = attrs.widgetSize
    options.light = attrs.light
    options.initialValue = (attrs.initialValue == "true")
    elem.first().xrxtoggleswitch(options)

    elem.first().on('slidechange', (event, ui) => {
      ngModel.$setViewValue(!!+ui.value)
      scope.onSwitch()
    })

    scope.$on('$destroy', () => {
      scope = null
    })
    elem.on('$destroy', () => {
      elem.first().xrxtoggleswitch('destroy')
      elem = null
    })
  }
  static directiveFactory() {
    xwToggleSwitch.instance = new xwToggleSwitch()
    return xwToggleSwitch.instance
  }
}
