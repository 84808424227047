/* --------------------------------------------------------------------------------------
   scan.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const scanComponent = {
  template: require('./scan.html'),
  controller: 'scanCtrl',
  controllerAs: 'vm'
}

export class scanCtrl {
  /*@ngInject*/
  constructor(
    appSettings,
    commonService,
    scanService,
    jobStatusService,
    dataService,
    customService,
    printService,
    sessionDataService,
    deviceInfoService,
    redactService,
    xeroxEIPParameters,
    markupTypes,
    $timeout,
    $translate,
    $state
  ) {
    this.appSettings = appSettings
    this.$timeout = $timeout
    this.$translate = $translate
    this.commonService = commonService
    this.scanService = scanService
    this.jobStatusService = jobStatusService
    this.customService = customService
    this.dataService = dataService
    this.printService = printService
    this.sessionDataService = sessionDataService
    this.deviceInfoService = deviceInfoService
    this.redactService = redactService
    this.xeroxEIPParameters = xeroxEIPParameters
    this.markupTypes = markupTypes
    this.$state = $state
  }
  async $onInit() {
    this.deviceInfo = await this.deviceInfoService.get()
    this.printOptions = this.customService.printOptions
    this.preset = this.sessionDataService.preset
    this.pageBalance = this.customService.balance
    this.emailAddress = this.sessionDataService.emailAddress
    this.twoSidedScanOptions = this.xeroxEIPParameters.sides.filter(side => side.scope.includes('scan'))
    this.twoSidedPrintOptions = this.xeroxEIPParameters.sides.filter(side => side.scope.includes('print'))
    this.originalSizeOptions = await this.xeroxEIPParameters.sizes
    this.outputColorOptions = this.xeroxEIPParameters.outputColor
    this.markupTypeOptions = this.markupTypes
    this.autoDetectOriginalSize = "auto";

    angular.element('#scrollable-container').xrxscrollable({ theme: 'violet' })
    this.setDefaults()
    this.loaded = true
    this.commonService.updateView()
  }
  setDefaults() {
    this.preview = true
    angular.element('#preview').xrxtoggleswitch("value", true)
    this.print = true
    angular.element('#print').xrxtoggleswitch("value", true)
    this.email = false
    angular.element('#email').xrxtoggleswitch("value", false)

    this.printOptions.copies = 1

    this.emailAddress = ''
    this.$translate('DEFAULT_SUBJECT').then(result => this.emailSubject = result)

    this.twoSidedScan = this.twoSidedScanOptions[0].key
    this.$translate(this.twoSidedScanOptions[0].translationKey).then(result => this.twoSidedScanText = result)

    this.originalSize = this.autoDetectOriginalSize // Auto Detect
    this.$translate('AUTO_DETECT').then(result => {
      this.autoDetectStr = result
      this.originalSizeText = result
    })

    this.markupType = this.markupTypeOptions[0].key
    this.$translate(this.markupTypeOptions[0].translationKey).then(result => this.markupTypeText = result)

    this.printOptions.sides = this.twoSidedPrintOptions[0].key
    this.$translate(this.twoSidedPrintOptions[0].translationKey).then(result => this.twoSidedPrintText = result)

    this.printOptions.outputColor = this.outputColorOptions[0].key
    this.$translate(this.outputColorOptions[0].translationKey).then(result => this.outputColorText = result)

  }
  togglePreview() {
    this.preview = !this.preview
    angular.element('#preview').xrxtoggleswitch("value", this.preview)
  }
  togglePrint() {
    this.print = !this.print
    angular.element('#print').xrxtoggleswitch("value", this.print)
  }
  toggleEmail() {
    this.email = !this.email
    angular.element('#email').xrxtoggleswitch("value", this.email)
  }
  selectTwoSidedScan(value) {
    this.twoSidedScan = this.twoSidedScanOptions[value.index].key
    this.twoSidedScanText = value.label
    this.commonService.updateView()
  }
  selectOriginalSize(item) {
    const selectedOption = this.originalSizeOptions.find(c => c.key == item.value)
    if (!selectedOption) {
      this.originalSize = this.autoDetectOriginalSize
      this.originalSizeText = this.autoDetectStr
      this.originalSizeOrientation = ""
    }
    else {
      this.originalSize = selectedOption.key
      this.originalSizeText = selectedOption.value;
      this.originalSizeOrientation = (item.index == 0) ? '' : selectedOption.orientation
    }

    this.commonService.updateView()
  }
  selectMarkupType(value) {
    this.markupType = this.markupTypeOptions[value.index].key
    this.markupTypeText = value.label
    this.commonService.updateView()
  }
  selectTwoSidedPrint(value) {
    this.printOptions.sides = this.twoSidedPrintOptions[value.index].key
    this.twoSidedPrintText = value.label
    this.commonService.updateView()
  }
  selectOutputColor(value) {
    this.printOptions.outputColor = this.outputColorOptions[value.index].key
    this.outputColorText = value.label
    this.commonService.updateView()
  }

  async scan() {
    this.scanning = true
    this.commonService.updateView()
    this.sessionDataService.emailAddress = this.emailAddress
    this.sessionId = this.commonService.getNewGuid()
    const duplex = (this.twoSidedScan != 'OneSided')

    try {
      await this.dataService.postRedactConfig(this.sessionId, this.getRedactConfig())
      this.scanJobId = await this.scanService.scan(this.sessionId, duplex, 0, this.originalSize, this.originalSizeOrientation)
      await this.jobStatusService.waitScan(this.scanJobId)
      this.scanning = false
      this.redacting = true      
      this.commonService.updateView()
      await this.jobStatusService.getScanFinishedStatus(this.scanJobId)
      await this.redactService.waitFileRedaction(this.sessionId)

      this.commonService.updateView()

      if (this.print && !this.preview) {
        this.redacting = false
        this.commonService.updateView()
        await this.printJob()
      }      
      if (!this.print && !this.preview) {
        await this.$timeout(() => this.updateBalance(), 2000)        
      }
      this.customService.printAfterPreview = (this.print && this.preview)
    } catch (e) {
      const tryagain = await this.commonService.handleError(e)
      this.error = tryagain
    } finally {
      this.scanning = false
      this.redacting = false
      this.commonService.updateView()
    }
  }
  getRedactConfig() {
    return {
      Email: this.emailAddress,
      EmailSubject: this.emailSubject,
      ShowPreview: this.preview,
      AppId: this.sessionDataService.appId,
      DeviceId: this.sessionDataService.deviceId,
      CustomFields: this.preset.CustomFields,
      StandardFields: this.preset.StandardFields.filter(field => field.IsToggled),
      MarkupType: this.markupType
    }
  }
  async scanningClosed() {
    await this.commonService.updateView()
    setTimeout(async () => {
      if (this.preview && !this.error) {
        this.previews = await this.getPreviews()
        await this.commonService.updateView()
      }
    }, 1000)
  }
  async getPreviews() {
    try {
      const size = await this.dataService.getPreviewPageCount(this.sessionId)
      if (size > 0) {
        let result = []
        for (var i = 0; i < size; i++) {
          result.push(this.dataService.apiUrl + '/redact/doc/' + this.sessionId + '/' + i)
        }
        return result
      }
      else {
        this.error = this.commonService.handleError({})
      }

    } catch (e) {
      this.error = this.commonService.handleError(e)
    }
  }
  async acceptJob() {
    const size = await this.dataService.getPreviewPageCount(this.sessionId);
    if ((this.pageBalance !== -1 && this.pageBalance < size)) {
      this.error = this.commonService.handleError({})
      this.cancelJob();
    } else {
      this.dataService.postApprove(
        this.sessionId,
        this.sessionDataService.appId,
        this.sessionDataService.deviceId
      );
      if (this.print) {
        await this.printJob();
      }
      this.updateBalance();
    }
  }
  updateBalance() {
    if (this.pageBalance !== -1) {
      this.customService.updateBalance(this.sessionDataService.appId, this.sessionDataService.deviceId)
        .then(() => {
          this.pageBalance = this.customService.balance
          this.commonService.updateView()
        })
    }
  }
  errorClosed() {
    this.error = null
    this.commonService.updateView()
  }
  reset() {
    this.setDefaults()
    angular.element("#scrollable-container").xrxscrollable("getIScroll").scrollTo(0, 0)
    angular.element(".shadow.top").css("display", "none")
    this.commonService.updateView()
  }
  refreshScroll() {
    angular.element("#scrollable-container").xrxscrollable("refresh")
  }
  onActionSwitch() {
    if (!this.email) {
      this.emailAddress = null
    }
    this.noPrintNoEmail = false
    this.validateForm()
    this.refreshScroll()
  }
  validateForm(emailAddress) {
    if (emailAddress)
      this.emailAddress = emailAddress
    if (this.loaded) {
      const formInvalid = ((this.email && !this.emailAddress) || (!this.email && !this.print))
      angular.element('#preview-button').xrxbutton({ disabled: formInvalid })
      angular.element('#scan-button').xrxbutton({ disabled: formInvalid })
      if (!this.email && !this.print) {
        this.noPrintNoEmail = true
        this.commonService.updateView()
      }
    }
  }
  async printJob() {
    if (this.isVersalink())
      await this.printService.SubmitPrint(this.sessionId, this.printOptions)
    else {
      this.printing = true
      const jobId = await this.printService.SubmitPrint(this.sessionId, this.printOptions)
      await this.jobStatusService.getPrintFinishedStatus(jobId)
      this.printing = false
      return true
    }
    this.updateBalance()
  }
  cancelRedaction() {
    //when clicking cancel on redacting spinner
    this.jobStatusService.cancelScan(this.scanJobId)
    this.dataService.postCancelRedact(this.sessionId)
    this.updateBalance()
    this.refreshScroll()
  }
  cancelJob() {
    //when not accepting the preview
    this.dataService.postCancelRedact(this.sessionId)
    this.updateBalance()
    this.refreshScroll()
  }
  isVersalink() {
    return this.deviceInfo.model.toLowerCase().includes('versa')
  }
}
