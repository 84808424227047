/* --------------------------------------------------------------------------------------
   main.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const mainComponent = {
  template: require('./main.html'),
  controller: 'mainCtrl',
  controllerAs: 'vm'
}

export class mainCtrl {
  /*@ngInject*/
  constructor(commonService, deviceInfoService, dataService, sessionDataService, $translate, $state, $sce) {
    this.commonService = commonService
    this.deviceInfoService = deviceInfoService
    this.dataService = dataService
    this.sessionDataService = sessionDataService
    this.$translate = $translate
    this.$state = $state
    this.privacyPopupId = "main";
    this.screenSize = this.commonService.getScreenSize();
    this.$sce = $sce;
  }
  async $onInit() {
    this.loading = true
    this.needsUpdate = !location.search.includes('&apiKey=')
    if (this.needsUpdate) {
      this.loading = false
      return
    } 
    try {
      this.dataService.getApiUrl()
      this.$translate('MAIN_TITLE').then(value => this.title = value)
      this.$translate('PRIVACY_STATEMENT').then(value => this.privacyStatementTitle = value)
      this.deviceInfo = await this.deviceInfoService.get()
      const language = navigator.language.toLowerCase()
      this.dataService.langCode = language !== 'pt-br' ? language.split('-')[0] : language
      this.presets = await this.dataService.getPresets(this.deviceInfo.serial)
      this.loading = false
      await this.commonService.updateView()
      if (!this.presets.length)
        this.$state.go('custom')
      this.commonService.setVioletBackground()
      this.ready = true
      this.sessionDataService.presets = this.presets
      await this.commonService.updateView()
    } catch (e) {
      this.error = true
      this.loading = false
      this.commonService.updateView()
    }
  }
  errorClosed() {
    this.error = false
    this.commonService.updateView()
  }
}
