/* --------------------------------------------------------------------------------------
   emailSubjectPopup.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const emailSubjectPopupComponent = {
  template: require('./emailSubjectPopup.html'),
  controller: 'emailSubjectPopupCtrl',
  controllerAs: 'vm',
  bindings: {
    display: '=',
    value: '='
  }
}

export class emailSubjectPopupCtrl {
  /*@ngInject*/
  constructor(commonService, $timeout) {
    this.commonService = commonService
    this.$timeout = $timeout
  }
  $onInit() {
    this.inputValue = this.value
    this.originalValue = this.value
  }
  cancel() {
    this.getKeyboard().close()
    this.inputValue = this.originalValue
    this.value = this.originalValue
    this.closePopup()
  }
  ok() {
    this.getKeyboard().accept()
    this.originalValue = this.inputValue
    this.value = this.inputValue
    this.closePopup()
  }
  open(){
      this.getKeyboard().reveal()
  }
  closePopup() {
    this.display = false
    this.commonService.updateView()
  }
  getKeyboard() {
    return angular.element("#email-subject-input").getkeyboard()
  }
}
