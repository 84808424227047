/* --------------------------------------------------------------------------------------
   scan.service.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export class scanService {
  /*ngInject*/
  constructor (appSettings, dataService) {
    this.appSettings = appSettings
    this.dataService = dataService
  }
  async scan (guid, duplex, darkness, originalSize, orientation) {
    const scanTicket = await this.dataService.postTemplate(guid, duplex, darkness, false, originalSize, orientation)
    const scanJob = await this.submitScanTicket(scanTicket.Data, this.appSettings.deviceUrl)
    return xrxScanV2ParseInitiateScanJob(scanJob.response)
  }
  submitScanTicket (ticket, deviceUrl) {
    return new Promise(function(resolve, reject) {
      submitScanTicket(
        deviceUrl,
        ticket,
        null,
        120,
        (env, response) => resolve({env, response}),
        (env, response, status) => reject({method: 'Submit Scan', env, response, status})
      )
    })
  }
}
