/* --------------------------------------------------------------------------------------
   data.service.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export class dataService {
  /*@ngInject*/
  constructor(appSettings, $http, $window, sessionDataService) {
    this.appSettings = appSettings
    this.$http = $http
    this.$window = $window
    this.langCode = 'en'  // this will be set after the first call but let's put a default anyways
    //set api key header
    if (!this.headers) this.headers = {}
    this.headers["x-api-key"] = sessionDataService.key
  }
  getApiUrl() {
    const host = this.$window.location.host
    console.log('host is: ' + host)

    if (host.includes('localhost'))
      this.isLocal = true

    if (this.isLocal && this.appSettings.localhostApi == 'local')
      this.apiUrl = this.appSettings.devApis.localhost
    else if (host.includes('-test') || (this.isLocal && this.appSettings.localhostApi == 'test'))
      this.apiUrl = this.appSettings.devApis.test
    else if (host.includes('-staging') || (this.isLocal && this.appSettings.localhostApi == 'staging'))
      this.apiUrl = this.appSettings.devApis.staging
    else if (host.includes('-demo') || (this.isLocal && this.appSettings.localhostApi == 'demo'))
      this.apiUrl = this.appSettings.devApis.demo
      
    if (!this.apiUrl)
      this.apiUrl = this.appSettings.api

    console.log('api is: ' + this.apiUrl)
  }
  async getPresets(deviceGuid) {
    const endpoint = this.apiUrl + '/profile/list/' + deviceGuid + '/' + this.langCode
    const result = await this.$http.get(endpoint, {headers: this.headers})
    return result.data
  }
  async getPreset(id) {
    const endpoint = this.apiUrl + '/profile/' + id
    const result = await this.$http.get(endpoint, {headers: this.headers})
    return result.data;
  }
  async postPreset (preset) {
    const endpoint = this.apiUrl + '/profile'
    const result = await this.$http.post(endpoint, preset, { headers: this.headers} )
    return result.data
  }
  async patchPreset(preset) {
    const endpoint = this.apiUrl + '/profile'
    const result = await this.$http.patch(endpoint, preset, { headers: this.headers} )
    return result.data
  }
  async deletePreset (id) {
    const endpoint = this.apiUrl + '/profile/' + id
    const result = await this.$http.delete(endpoint, {headers: this.headers})
    return result.data
  }
  async getInfotypes(countryCode) {
    const endpoint = this.apiUrl + '/fields/standard/' + countryCode + '/' + this.langCode
    const result = await this.$http.get(endpoint, {headers: this.headers})
    return result.data.filter((obj1, i, arr) => 
      arr.findIndex(obj2 => (obj2.GoogleId === obj1.GoogleId)) === i
    )
  }
  async getStandardFields(countryCode, serial) {
    const endpoint = this.apiUrl + '/fields/standard/' + countryCode + '/' + this.langCode + '/' + serial
    const result = await this.$http.get(endpoint, {headers: this.headers})
    return result.data.filter((obj1, i, arr) => 
      arr.findIndex(obj2 => (obj2.GoogleId === obj1.GoogleId)) === i
    )
  }
  async patchStandardFields(countryCode, serial, fields) {
    const endpoint = this.apiUrl + '/fields/standard/' + countryCode + '/' + serial
    const result = await this.$http.patch(endpoint, fields, {headers: this.headers})
    return result.data
  }
  async postRedactConfig(sessionId, config) {
    const endpoint = this.apiUrl + '/redact/config/' + sessionId
    const result = await this.$http.post(endpoint, config, {headers: this.headers})
    return result.data
  }
  async getRedactJobStatus(sessionId) {
    const endpoint = this.apiUrl + '/redact/status/' + sessionId
    const result = await this.$http.get(endpoint, {headers: this.headers})
    return result.data
  }
  async getRedactJobError(sessionId) {
    const endpoint = this.apiUrl + '/redact/error/' + sessionId
    const result = await this.$http.get(endpoint, {headers: this.headers})
    return result.data
  }
  async postTemplate(guid, duplex, darkness, multi, originalSize, orientation) {
    const config = {
      id: guid,
      duplex,
      darkness,
      multi,
      original_size: originalSize ? originalSize : "NA_8.5x11LEF",
      is_landscape: orientation == "L" ? true : false
    };
    const endpoint = this.apiUrl + '/scan/template'
    const result = await this.$http.post(endpoint, config, { headers: this.headers} )
    return result.data
  }
  async getPreviewPageCount(sessionId) {    
    const endpoint = this.apiUrl + '/redact/doc/pages/' + sessionId + '/count'
    const result = await this.$http.get(endpoint, {headers: this.headers})
    return result.data.count
  }
  async getPdfPreview(sessionId) {
    const endpoint = this.apiUrl + '/redact/doc/' + sessionId + '?docType=1'
    const result = await this.$http.get(endpoint, {headers: this.headers})
    return result.data
  }
  async postApprove(sessionId, appId, deviceId) {
    const endpoint = this.apiUrl + '/redact/approve/' + sessionId + '/' + appId + '/' + deviceId
    const result = await this.$http.post(endpoint, null, { headers: this.headers} )
    return result.data
  }
  async postCancelRedact(sessionId) {
    const endpoint = this.apiUrl + '/redact/cancel/' + sessionId
    const result = await this.$http.post(endpoint, null, { headers: this.headers} )
    return result.data
  }
  async postLog(message) {
  const endpoint = this.apiUrl + '/log'
  const result =  await this.$http.post(endpoint, {data: message}, {headers: this.headers()})
  return result.data
  }
  async getPageBalance(appId, deviceId) {
    if (!deviceId || !appId)
      return 0
    const endpoint = this.apiUrl + '/redact/entitlement/' + appId + '/' + deviceId
    const result = await this.$http.get(endpoint, {headers: this.headers})
    return result.data
  }
  setHeaders(serial, secret, appId, deviceId) {
    this.headers = {
      DeviceSerial: serial,
      DeviceSecret: secret,
      AppId: appId,
      DeviceId: deviceId
    }
    this.eCommerceParameters = '/' + appId + '/' + deviceId
  }
}

let getListEndpoint = (address, userData, entity, params) => {
  var endpoint = address + '/' + entity + '/list/'
  endpoint = endpoint + getEndpointParameters(userData, params)
  return endpoint
}

let getEndpointParameters = (userData, params) => {
  let result = '/' + userData.client_id + '/' +
    userData.account_id + '/' +
    userData.user_id
  for (let i = 0; i < params.length; i++) {
    result = result + '/' + params[i];
  }
  return result;
}