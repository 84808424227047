/* --------------------------------------------------------------------------------------
   jobStatus.service.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export class jobStatusService {
  /*@ngInject*/
  constructor(appSettings, $q, $timeout) {
    this.appSettings = appSettings
    this.$timeout = $timeout
  }
  getScanFinishedStatus(jobId) {
    return this.getFinishedStatus(jobId, "WorkflowScanning")
  }
  getPrintFinishedStatus(jobId) {
    return this.getFinishedStatus(jobId, "Print")
  }
  async waitScan(jobId) {
    const jobDetails = await this.getJobDetails(this.appSettings.deviceUrl, "WorkflowScanning", jobId)
    const status = this.parseStatus(jobDetails.response)
    if (status.reasons == 'JobAborted' || status.status == 'Canceled')// || status.status == 'ProcessingStopped')
      return Promise.reject('aborted')
    if (status.reasons == 'InputScanSizeNotDetermined')
      return Promise.reject('InputScanSizeNotDetermined')
    if (status.reasons == 'Transferring' || status.status == 'Completed')
      return true
    else
      return this.$timeout(angular.noop, 2000).then(() => {
        return this.waitScan(jobId)
      })
  }
  async waitProcessing(jobId) {
    const jobDetails = await this.getJobDetails(this.appSettings.deviceUrl, "WorkflowScanning", jobId)
    const status = this.parseStatus(jobDetails.response)

    if (status.status == 'Aborted' || status.status == 'Canceled' || status.status == 'ProcessingStopped')
      return Promise.reject('aborted')

    if (status.reasons == 'Transferring')
      return this.$timeout(angular.noop, 2000).then(() => {
        return this.waitScan(jobId)
      })
    else
      return true
  }
  async getFinishedStatus(jobId, jobType) {
    const jobDetails = await this.getJobDetails(this.appSettings.deviceUrl, jobType, jobId)
    const jobState = this.getJobState(jobDetails.env, jobDetails.response)
    if (jobState == "ProcessingStopped") return Promise.reject()
    if (!(jobState == "Aborted" || jobState == "Canceled" || jobState == "ProcessingStopped" || jobState == "Completed")) {
      return this.$timeout(angular.noop, 2000).then(() => {
        return this.getFinishedStatus(jobId, jobType)
      })
    }
    else {
      return jobState
    }
  }
  parseStatus(jobDetailsResponse) {
    const jobDetails = xrxJobMgmtParseGetJobDetails(jobDetailsResponse);
    const jobStateNode = xrxFindElement(jobDetails, ["JobInfo", "JobState"])
    const jobStateReasonsNode = xrxFindElement(jobDetails, ["JobInfo", "JobStateReasons"])
    return { status: xrxGetValue(jobStateNode), reasons: xrxGetValue(jobStateReasonsNode) }
  }
  getJobState(env, response) {
    const jobDetails = xrxJobMgmtParseGetJobDetails(response);
    const jobStateNode = xrxFindElement(jobDetails, ["JobInfo", "JobState"]);
    const jobState = xrxGetValue(jobStateNode);
    //var jobStateFormatted = jobState.replace("Held", "").replace("Stopped", "");
    return jobState
  }
  async getJobStatus(jobId) {
    //alert open
    const jobDetails = await this.getJobDetails(this.appSettings.deviceUrl, "WorkflowScanning", jobId)
    return this.jobStatusSuccess(jobDetails.env, jobDetails.response)
  }
  getJobDetails(deviceUrl, jobType, jobId) {
    return new Promise((resolve, reject) => {
      xrxJobMgmtGetJobDetails(
        deviceUrl,
        jobType,
        jobId,
        (env, response) => resolve({ env, response }),
        (env, response) => reject({ method: 'Get Job Details', env, response }),
        10)
    })
  }
  cancelScan(jobId) {
    return this.cancelJob('WorkflowScanning', jobId)
  }
  cancelJob(jobType, jobId) {
    return new Promise((resolve, reject) => {
      xrxJobMgmtCancelJob(
        null,
        jobType,
        jobId,
        (env, response) => resolve({ env, response }),
        (env, response) => reject({ method: 'Cancel Job', env, response }),
        10)
    })
  }


}
