/* --------------------------------------------------------------------------------------
   common.service.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export class commonService {
  /*@ngInject*/
  constructor (persistService, deviceInfoService, $translate, $timeout, $window, $state) {
    this.persistService = persistService
    this.$translate = $translate
    this.$timeout = $timeout
    this.$window = $window
    this.$state = $state
    this.deviceInfoService = deviceInfoService
  }
  setVioletBackground () {
    const body = angular.element('body')
    if (!body.hasClass('violet'))
        body.addClass('xrx-background violet')
  }
  setDefaultBackground () {
    angular.element('body').removeClass('xrx-background violet')
  }
  validateDate(date) {
    if (date == null || date.length < 1)
      return false
    console.log(date)
    return true
  }
  updateView() {
    return this.$timeout(() => 1 == 1)
  }
  async handleError(error) {
    const result = await this.$translate('TRY_AGAIN')

    return result
    /*try {
      const refnum = await this.dataService.postLog(error)
      return await this.$translate(['GENERIC_ERROR','REFERENCE_NUMBER'], {ref: refnum})
    } catch (e) {
      return await this.$translate('TRY_AGAIN')
    }*/
  }
  getNewGuid() {
  //// TEMP: when scan profile available this will not be needed
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
      return v.toString(16);
    });
  }
 getScreenSize() {
    return (angular.element(this.$window).width() > 801)? 10 : 7
  }
  getDeviceLocation() {
    const location = this.persistService.getObject('location')
    const defaultLocation = {code:'us',name:'United States'}
    if (!location)
      return defaultLocation
    return location
  }
  updateLocation(location) {
    if (location && location.code && location.name) {
      this.persistService.setObject('location', location)
    }
  }
  getCountries() {
    return [
      {code:"Argentina",name: "Argentina"},
      {code:"Australia",name: "Australia"},
      {code:"Belgium",name: "Belgium"},
      {code:"Brazil",name: "Brazil"},
      {code:"Canada",name: "Canada"},
      {code:"Chile",name: "Chile"},
      {code:"China",name: "China"},
      {code:"Colombia",name: "Colombia"},
      {code:"Denmark",name: "Denmark"},
      {code:"Finland",name: "Finland"},
      {code:"France",name: "France"},
      {code:"Germany",name: "Germany"},
      {code:"Hong_Kong",name: "Hong Kong"},
      {code:"India",name: "India"},
      {code:"Indonesia",name: "Indonesia"},
      {code:"Ireland",name: "Ireland"},      
      {code:"Italy",name: "Italy"},
      {code:"Japan",name: "Japan"},
      {code:"Korea",name: "Korea"},
      {code:"Mexico",name: "Mexico"},
      {code:"Netherlands",name: "The Netherlands"},
      {code:"Norway",name: "Norway"},
      {code:"Paraguay",name: "Paraguay"},
      {code:"Peru",name: "Peru"},
      {code:"Poland",name: "Poland"},
      {code:"Portugal",name: "Portugal"},
      {code:"Singapore",name: "Singapore"},
      {code:"Spain",name: "Spain"},
      {code: "SOUTH_AFRICA", name: "South Africa" },
      {code:"Sweden",name: "Sweden"},
      {code:"Taiwan",name: "Taiwan"},
      {code:"Thailand",name: "Thailand"},
      {code:"Turkey",name: "Turkey"},
      {code:"uk",name: "United Kingdom"},
      {code:"Uruguay",name: "Uruguay"},
      {code:"us",name: "United States"},
      {code:"Venezuela",name: "Venezuela"}
    ]
  }
  exitApp() {
    try {
      ExitCUIMode()      
    } catch (e) {
      console.log("Exit CUI embedded function not found, switching to empty state instead")
      this.$state.go('empty')      
    }    
  }

  getNavigatorLanguage() {
    return navigator.language.split("-")[0].toLowerCase();
  }
  async getDeviceInfo(){
    return this.deviceInfoService.get();
  }
  async isNewVersa(){
    let deviceInfo = await this.getDeviceInfo();
    return deviceInfo.model.toLowerCase() == "versa" && deviceInfo.eipSoftware >= 5
  }
}
