import { persistService } from "../common/services/persist.service";

/* --------------------------------------------------------------------------------------
   privacyStatement.service.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export class disclaimerService {
  /* @ngInject */
  constructor($http, persistService) {
    this.$http = $http
    this.persistService = persistService
  }
  isEulaAccepted() {
    var lastAccepted = this.persistService.getString("ard-eula");
    return lastAccepted !== null && lastAccepted !== undefined && lastAccepted !== "";
  }
  acceptEula() {
    this.persistService.setString("ard-eula", (new Date()).toUTCString())
  }
  async getPrivacyStatement(language) {
    const result = await this.$http.get('https://appgallery.services.xerox.com/api/apps/template-privacy-policy', { headers: { 'Accept-Language': language } })
    return result;
  }

  async getEula(language) {
    const result = await this.$http.get(`./eulaText/eula_${language}.txt`)
    return result;
  }
}
