/* --------------------------------------------------------------------------------------
   infotypes.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const infotypesComponent = {
  template: require('./infotypes.html'),
  controller: 'infotypesCtrl',
  controllerAs: 'vm',
  bindings: {
    infotypes: '<',
    standardFields: '=',
    refreshScroll: '&',
    enableSave: '&',
    serial: '<',
    countryCode: '<'
  }
}

export class infotypesCtrl {
  /*@ngInject*/
  constructor(dataService, commonService,$translate, $filter, $timeout ) {
    this.dataService = dataService
    this.commonService = commonService
    this.$translate = $translate
    this.$filter = $filter
    this.$timeout = $timeout
  }

  $onInit() {
    this.$translate('STANDARD_REDACTION_ITEMS').then(result => this.title = result)
    this.$translate('SEARCH').then(result => this.searchButtonText = result)
    //initialize input
    this.filter = { DisplayText: '' }
  }
  $onChanges(changeObj) {
    if (changeObj.hasOwnProperty('infotypes') && this.infotypes) {
      const popupOptions = {
        theme: 'violet',
        title: this.title,
        close: () => this.closePopup(),
      }
      angular.element('#infotypes-popup').xrxpopup(popupOptions)
      this.changedStandardFields = [...this.standardFields]
      setTimeout(angular.noop(),2000)
      angular.element('#infotypes-table').xrxtable({
        theme: 'violet',
        bordered: true,
        width: (this.commonService.getScreenSize() == 7)? '790' : '863',
        height: (this.commonService.getScreenSize() == 7)? '311' : '320',
        scroll: true,
        widgetSize: 'xrx-small'
      })
      
      if (this.commonService.getScreenSize() == 7)
        popupOptions.height = 800
      //if(angular.element('#infotypes-popup').xrxpopup())
        this.filter = { DisplayText: '' }
      this.search = false
      angular.element('#hint-popover').xrxpopover('setTargetControl', '#' + this.getInfotypeRowId(this.infotypes[0].GoogleId), 'xrx-arrow-top');
      this.commonService.updateView()
      angular.element('#search-button').xrxbutton({label: this.searchButtonText})
      angular.element('#infotypes-popup').xrxpopup('open')
      if (this.init) this.refreshFilterScroll() 
      this.init = true
    }
  }
  selectDeselect(item) {
    item.selected = !item.selected;
    item.IsToggled = !item.isToggled;

    let itemIsSelected = item.selected
    if(!('WasToggled' in item)){
      item.WasToggled = true
    }
    if(itemIsSelected){
      this.changedStandardFields.push(item)
    }
    else{
      this.changedStandardFields = this.changedStandardFields.filter(field => field.GoogleId != item.GoogleId)
    }
    this.changedStandardFields = this.$filter('orderBy')(this.changedStandardFields, 'DisplayText')
  }
  async openSearchBox() {
    this.search = true
    await this.commonService.updateView()
    angular.element('#search-box').getkeyboard().reveal()
  }
  updateFilter() {
    this.filter.DisplayText = angular.element('#search-box').getkeyboard().$preview.val()
    this.searchBoxText = this.filter.DisplayText
    this.refreshFilterScroll()
    this.commonService.updateView()
  }
  beforeCloseSearchInput() {
    this.filter.DisplayText = this.searchBoxText
    this.commonService.updateView()
  }
  closeSearchBox() {
    angular.element('#search-box').getkeyboard().close()
    this.filter.DisplayText = '';
    angular.element('#search-button').xrxbutton({label: this.filter.DisplayText})
    this.search = false
    this.refreshFilterScroll();
  }
  async closePopup() {
    //await this.dataService.patchStandardFields(this.countryCode, this.serial, this.changedStandardFields)
    this.filter = {DisplayText: ''}
    if (this.standardFields !== this.changedStandardFields)
      this.enableSave()
    this.standardFields = [...this.changedStandardFields]
    this.commonService.updateView()
    this.refreshScroll()
  }
  getInfotypeRowId(googleId) {
    return googleId.split("/").slice(-1)[0]
  }
  getInfotypePopoverId(googleId) {
    return "po-" + googleId.split("/").slice(-1)[0]
  }

  refreshFilterScroll(){
    console.log('refreshFilterScroll')
      this.commonService.updateView()
      //angular.element('#infotypes-popup').xrxscrollable();
      angular.element('#infotypes-popup').xrxscrollable('refresh')
      angular.element("#infotypes-popup").xrxscrollable("getIScroll").scrollTo(0, 0)
  }
  
}
