/* --------------------------------------------------------------------------------------
   xwSegmented.directive.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

/*eslint semi: ["error", "never"]*/
 export class xwSegmented {
  /*@ngInject*/
  constructor () {
    this.restrict = 'A'
    this.scope = {
      onSelect: '&'
    }
  }
  link(scope, elem, attrs) {
    var options = {
      width: attrs.width,
      height: attrs.height,
      theme: attrs.theme,
      type: attrs.type,
      customClass: attrs.customClass,
      highlightSelection: attrs.highlightSelection,
      select: (event, ui) => {
        scope.onSelect({item: ui.item})
        // Workaround to prevent multiple selected items when inside a popover
        // Can be enhanced with a logic to save last selected item and keep it highlighted
        if (attrs.hasOwnProperty('isPopover') && !attrs.highlightSelection){
          $('.ui-state-selected').removeClass('ui-state-selected')
        }
      }
    }
    options.width = attrs.width
    options.theme = attrs.theme
    setTimeout(() => elem.first().xrxsegmentedcontrol(options))

    elem.on('$destroy', () => {
      if (elem.first().css('display') == 'none')
        elem.first().xrxsegmentedcontrol('destroy')
    })
  }
  static directiveFactory() {
    xwSegmented.instance = new xwSegmented()
    return xwSegmented.instance
  }
}
