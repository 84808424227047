/* --------------------------------------------------------------------------------------
   emailAddressPopup.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const emailAddressPopupComponent = {
  template: require('./emailAddressPopup.html'),
  controller: 'emailAddressPopupCtrl',
  controllerAs: 'vm',
  bindings: {
    display: '=',
    value: '=',
    onClose: '&'
  }
}

export class emailAddressPopupCtrl {
  /*@ngInject*/
  constructor(appSettings, commonService) {
    this.appSettings = appSettings
    this.commonService = commonService
  }
  $onInit() {
    this.inputValue = this.value
    this.originalValue = this.value
    this.invalidEmail = false
  }
  inputCancel() {
    const isValid = this.validateEmail(this.getKeyboard().$preview.val())
    if (this.invalidEmail && isValid) {
      this.invalidEmail = false
      this.commonService.updateView()
    }
    angular.element('#email-address-popup-ok-button').xrxbutton({disabled: !!!isValid})
  }
  inputAccepted() {
    const isValid = this.validateEmail(this.inputValue)
    this.invalidEmail = !isValid
    angular.element('#email-address-popup-ok-button').xrxbutton({disabled: !!!isValid})
    this.commonService.updateView()
  }
  cancel() {
    this.getKeyboard().close()
    this.value = this.originalValue
    this.inputValue = this.originalValue
    this.invalidEmail = false
    this.closePopup()
  }
  ok() {
    this.getKeyboard().accept()
      this.originalValue = this.inputValue
      this.value = this.inputValue
    this.closePopup()
  }
  open() {
    setTimeout(() => {
      angular.element("#email-address-input").click()      
    }, 700);
  }
  closePopup() {
    this.display = false
    this.commonService.updateView()
    this.onClose({email: this.invalidEmail? this.originalValue : this.inputValue})
  }
  getKeyboard() {
    return angular.element("#email-address-input").getkeyboard()
  }
  validateEmail(email){
    return this.appSettings.emailRegex.test(email)
  }
}
