/* --------------------------------------------------------------------------------------
   sessionData.service.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export class sessionDataService {
  /*@ngInject*/
  setAppGallerySettingsFromUrl() {
    var params = this.getQueryStringParams(location.search);
    this.appId = params.appid; // use lowercase key
    this.deviceId = params.deviceid; // use lowercase key
    this.key = params.apikey;
  }
  getQueryStringParams() {
    var regex = /[?&]([^=#]+)=([^&#]*)/gi, // add "i" flag for case-insensitivity
      url = location.search,
      params = {},
      match;

    while (match = regex.exec(url)) {
      const value = decodeURIComponent(match[2]).replace(/</g, '&lt;').replace(/>/g, '&gt;');
      params[match[1].toLowerCase()] = value      
    }

    return params;
  }

}
