/* --------------------------------------------------------------------------------------
   persist.service.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved. 
   
   Copyright protection claimed includes all forms and matters of copyrightable material 
   and information now allowed by statutory or judicial law or hereinafter granted, 
   including without limitation, material generated from the software programs which 
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export class persistService {
    /*@ngInject*/
  constructor ($window, moment) {
    this.$window = $window
    this.moment = moment
    this.storage = this.$window.localStorage
  }
  setObject(key, value) {
    this.setString(key, angular.toJson(value))
  }
  getObject(key) {
    return angular.fromJson(this.getString(key))
  }
  setMoment(key, value) {
    this.setString(key, value.format())
  }
  getMoment(key) {
    return this.moment(this.getString(key))
  }
  setString(key, value) {
    this.storage.setItem(key, value)
  }
  getString(key) {
    return this.storage.getItem(key)
  }
}
