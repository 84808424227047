/* --------------------------------------------------------------------------------------
   preview.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const previewComponent = {
  template: require('./preview.html'),
  controller: 'previewCtrl',
  controllerAs: 'vm',
  bindings: {
    previews: '=',
    onAccepted: '&',
    onCancel: '&'
  }
}

export class previewCtrl {
  constructor(commonService, deviceInfoService) {
    this.deviceInfoService = deviceInfoService
    this.commonService = commonService
  }
  async $onInit() {
    this.alta = this.isAltalink()
  }
  async isAltalink() {
    const deviceInfo = await this.deviceInfoService.get()
    return deviceInfo.model.toLowerCase().includes('alta')
  }
  async $onChanges() {
    if (this.previews && this.previews.length) {
      await this.commonService.updateView()
      angular.element('#preview-alert').xrxpopup('open')
    }
  }
  setScroll() {
    angular.element('#preview-single-img-container').xrxscrollable()
  }
  close() {
    if (this.selectedImg)
      this.selectedImg = null
    else {
      this.previews = null
      return this.onCancel()
    }
  }
  accept() {
    this.previews = null
    return this.onAccepted()
  }
  selectedCount() {
    if (!this.previews)
      return 0
    return this.previews.filter(prev => prev.selected).length
  }
  selectAll() {
    this.previews.forEach(prev => prev.selected = true)
  }
}
