/* --------------------------------------------------------------------------------------
   savePresetPopup.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const savePresetPopupComponent = {
  template: require('./savePresetPopup.html'),
  controller: 'savePresetPopupCtrl',
  controllerAs: 'vm',
  bindings: {
    display: '=',
    onClose: '&'
  }
}

export class savePresetPopupCtrl {
  /*@ngInject*/
  constructor(appSettings, commonService, sessionDataService) {
    this.appSettings = appSettings
    this.commonService = commonService
    this.sessionDataService = sessionDataService
  }
  $onInit() {
    this.savedPresetNames = this.sessionDataService.presets.map(preset => preset.Name.toLowerCase())
  }
  change() {
    const val = this.getKeyboard().$preview.val()
    const hasValue = !!val.length
    angular.element('#save-preset-popup-ok-button').xrxbutton({disabled: !hasValue})
  }
  cancel() {
    this.getKeyboard().close()
    this.inputValue=null;
    this.closePopup()
  }
  ok() {
    this.getKeyboard().accept()
    this.onClose({name: this.inputValue})
    this.closePopup()
  }
  closePopup() {
    this.display = false
    this.commonService.updateView()
  }
  open(){
    this.getKeyboard().reveal();
  }
  getKeyboard() {
    return angular.element("#save-preset-input").getkeyboard()
  }
}
